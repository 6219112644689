<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Users</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/new-user">
          <v-icon left dark>mdi-plus</v-icon>New User
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>

            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="users"
              :search="search"
            >
              <template v-slot:item.name="{ item }">
                <v-btn color="primary" text :to="`/users/${item.id}`">{{
                  item.name
                }}</v-btn>
              </template>

              <template v-slot:item.status="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.status == 1 ? "Active" : "In Active" }}</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      users: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        { text: "Expires On", value: "active_until" },
      ],
    };
  },
  methods: {
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.users = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
          self.loading = false;
        });
    },
    changeStatus() {},
  },
  created() {
    this.getUsers();
  },
};
</script>

<style scoped>
</style>